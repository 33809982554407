<template>
  <div class="production-history">
    <div class="video-wrapper">
      <div v-if="clicked"
           class="history-player-area">
        <div class="player-wrapper">
          <flv-player history
                      ref="player"
                      :switch-url="switchUrl"
                      :video-url="videoUrl"></flv-player>
        </div>
        <div class="video-infos">
          <div class="title">播放文件详情</div>
          <div class="desc-wrapper">
            <div class="desc">自编号：{{video.number}}</div>
            <div class="desc">通道号：{{video.tdh}}</div>
            <div class="desc">开始时间：{{video.st}}</div>
            <div class="desc">结束时间：{{video.et}}</div>
            <div class="desc">文件大小：{{video.size}}</div>
          </div>
        </div>
      </div>
      <div class="no-data"
           v-else>
        <img src="/img/no-data.png">
      </div>
    </div>
    <div class="right-data">
      <div class="operations">
        <el-form :model="form"
                 inline
                 ref="form">
          <el-form-item label="车辆"
                        label-width="80px"
                        :rules="{required: true,message:'请选择车辆信息'}"
                        prop="carid">
            <el-select style="width: 100%;"
                       size="mini"
                       filterable
                       clearable
                       v-model="form.carid">
              <el-option v-for="n in carList"
                         :key="n.id"
                         :label="n.number"
                         :value="n.carDeviceId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="摄像头"
                        label-width="80px"
                        :rules="{required: true,message:'请选择摄像头'}"
                        prop="tdh">
            <el-select style="width: 100%;"
                       size="mini"
                       filterable
                       clearable
                       v-model="form.tdh">
              <el-option v-for="n in tdhList"
                         :key="n.id"
                         :label="n.label"
                         :value="n.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始时间"
                        label-width="80px"
                        :rules="{required: true,message:'请选择开始时间'}"
                        prop="beginTime">
            <el-date-picker style="width: 100%;"
                            size="mini"
                            value-format="yyyyMMddHHmmss"
                            type="datetime"
                            v-model="form.beginTime"></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间"
                        label-width="80px"
                        :rules="{required: true,message:'请选择结束时间'}"
                        prop="endTime">
            <el-date-picker style="width: 100%;"
                            size="mini"
                            value-format="yyyyMMddHHmmss"
                            type="datetime"
                            v-model="form.endTime"></el-date-picker>
          </el-form-item>
        </el-form>
        <el-button type="primary"
                   size="mini"
                   :loading="loading"
                   @click="getVideoInfo">查询</el-button>
      </div>
      <div class="table-data">
        <el-table :data="videoSource"
                  style="height:100%;overflow-y: auto;"
                  stripe
                  size="mini"
                  :border="true"
                  @row-dblclick="selectVideo">
          <el-table-column header-align="center"
                           align="center"
                           v-for="h in headers"
                           :key="h.id"
                           :label="h.label"
                           :prop="h.prop"
                           :width="h.width"
                           :min-width="h.minWidth"
                           :show-overflow-tooltip="h.showTips"
                           :formatter="h.formatter"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import axios from 'axios'
import { createCustomChart } from '../../../utils/echartUtils.js'
import { videoType, restfulUrl, webSocketUrl, historyFileUrl } from '@/config'
import FlvPlayer from '@/components/flv-player.vue'

export default {
  name: 'production-history',
  components: { FlvPlayer },

  data() {
    return {
      loading: false,
      form: {
        func: 1004,
        tdh: 3,
        carid: ' ',
        beginTime: '',
        endTime: '',
      },
      carList: [],
      tdhList: [
        {
          id: 1,
          label: '行驶记录',
        },
        {
          id: 2,
          label: '驾驶员状态',
        },
        {
          id: 3,
          label: '左前支撑腿',
        },
        {
          id: 4,
          label: '右前支撑腿',
        },
        {
          id: 5,
          label: '左后支撑腿',
        },
        {
          id: 6,
          label: '右后支撑腿',
        },
        {
          id: 7,
          label: '固定转塔',
        },
        {
          id: 8,
          label: '末端软管',
        },
      ],
      switchUrl: '',
      videoUrl: '',
      clicked: false,
      videoSource: [],
      video: {},
      headers: [
        {
          id: 2,
          label: '开始时间',
          prop: 'stime',
          formatter: (row, column, cellValue) => {
            let year = cellValue.substring(0, 4)
            let month = cellValue.substring(4, 6)
            let day = cellValue.substring(6, 8)
            let hour = cellValue.substring(8, 10)
            let minute = cellValue.substring(10, 12)
            let second = cellValue.substring(12, 14)
            return (
              year +
              '/' +
              month +
              '/' +
              day +
              '/' +
              ' ' +
              hour +
              ':' +
              minute +
              ':' +
              second
            )
          },
        },
        {
          id: 3,
          label: '结束时间',
          prop: 'etime',
          formatter: (row, column, cellValue) => {
            let year = cellValue.substring(0, 4)
            let month = cellValue.substring(4, 6)
            let day = cellValue.substring(6, 8)
            let hour = cellValue.substring(8, 10)
            let minute = cellValue.substring(10, 12)
            let second = cellValue.substring(12, 14)
            return (
              year +
              '/' +
              month +
              '/' +
              day +
              '/' +
              ' ' +
              hour +
              ':' +
              minute +
              ':' +
              second
            )
          },
        },
        {
          id: 5,
          label: '大小',
          prop: 'length',
          formatter: (row, column, cellValue) => {
            return Math.floor(cellValue / 1024 / 1024) + 'MB'
          },
        },
      ],
    }
  },
  methods: {
    getVideoInfo() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const start = dayjs(this.form.beginTime)
          const end = dayjs(this.form.endTime)
          if (start > end) {
            return this.$opts.alert('开始时间不能晚于结束时间!')
          }
          // if (start.month() !== end.month()) {
          //   return this.$opts.alert('开始时间与结束时间不能跨月!')
          // }
          if (end.diff(start, 'day') > 3) {
            return this.$opts.alert('开始时间与结束时间不能超过3天!')
          }
          this.clicked = false
          let reqCount = 0
          this.loading = true
          this.videoSource = []
          // 请求单个通道
          const getChannel = () => {
            axios
              .get(restfulUrl + '/api.json', {
                params: {
                  tdh: this.form.tdh,
                  ...this.form,
                },
              })
              .then((response) => {
                if ([1, -85].includes(response.data.code)) {
                  // 接口1正常返回，延时请求接口2
                  axios
                    .get(
                      historyFileUrl +
                        `/?json={%22tid%22:%22${this.form.carid}%22,%22cmd%22:%221009%22,%22ticket%22:%2243b2f84b-6e2d-4c4a-87d6-ab67e8c883ae%22}`
                    )
                    .then((res) => {
                      if (res.data.code === 1) {
                        // 接口2正常返回
                        if (res.data.media_list) {
                          this.videoSource = this.videoSource.concat(
                            res.data.media_list.filter(
                              (m) =>
                                m.type === '0' ||
                                m.type === '2' ||
                                m.type === '3'
                            )
                          )
                          console.log(this.videoSource)
                        }
                      } else {
                        // 接口2异常
                        this.$opts.alert(res.data.message)
                      }

                      this.loading = false
                    })
                } else {
                  this.$message({
                    type: 'warning',
                    message: '当前设备实时监控视频离线',
                  })
                  this.loading = false
                }
              })
          }
          getChannel()
        }
      })
    },
    selectVideo(row) {
      console.log(this.clicked)
      if (this.clicked) {
        this.click = false
        this.video = {}
        this.switchUrl = ''
        this.videoUrl = ''
      }

      console.log(row)
      let tdh = this.form.tdh
      this.video = {
        number: this.carList.find((c) => c.carDeviceId === this.form.carid)
          .number,
        tdh: this.tdhList.find((c) => c.id === this.form.tdh).label,
        st: dayjs(row.stime).format('YYYY-MM-DD HH:mm:ss'),
        et: dayjs(row.etime).format('YYYY-MM-DD HH:mm:ss'),
        size: Math.round(row.length / 1024 / 1024) + 'MB',
      }
      const param = {
        tdh: tdh,
        type: 1,
        tid: this.form.carid,
      }
      this.switchUrl =
        restfulUrl +
        `/api.json?func=1005&carid=${this.form.carid}&tdh=${this.form.tdh}&beginTime=${row.stime}&endTime=${row.etime}`
      this.videoUrl =
        webSocketUrl +
        `/websocket?token=&params=${encodeURIComponent(JSON.stringify(param))}`
      this.clicked = true
    },
  },

  async created() {
    let data = await this.$http({
      url: this.$http.adornUrl('/car/list'),
      method: 'post',
      data: {},
    })
    this.carList = data.datas
  },

  //   beforeDestroy() {
  //     this.destroyChart()
  //   },
}
</script>

  <style lang="scss">
.production-history {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  .video-wrapper {
    width: 400vh;
    height: calc(85vh - 20px);
    margin-bottom: 20px;
    min-height: 400px;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;
    video {
      width: 100%;
      height: 100%;
    }
    .history-player-area {
      padding: 28px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      background-image: url('/img/history-bg.png');
      background-size: 100% 100%;
      .player-wrapper {
        width: 96%;
        height: 90%;
      }
      .video-infos {
        width: 100%;
        margin-left: 10px;
        margin-top: 10px;
        .title {
          color: #e60012;
          text-align: center;
          font-size: 17px;
          margin-bottom: 10px;
        }
        .desc-wrapper {
          display: flex;
          justify-content: space-between;
          .desc {
            color: #1a1a1a;
            font-size: 15px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .right-data {
    width: 250vh;
    height: 100%;
    min-height: 350px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(103, 103, 103, 0.1);
    border-radius: 4px;
    padding-bottom: 10px;
    .operations {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid #eeeeee;
      padding: 15px 20px;
      .el-form-item {
        width: 100%;
        margin-right: 30px;
        margin-bottom: 10px;

        .el-form-item__content {
          width: 80%;
        }
      }

      button {
        width: 40%;
      }
    }
    .table-data {
      height: 50vh;
    }
  }
  .no-data {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 999;
  }
}
</style>
